import useSessionEnding from "./UseSessionEnding";

import style from './SessionDebugTimer.module.css';

const SessionDebugTimer = () => {
  const { state } = useSessionEnding(() => { });

  return (
    <table cellPadding="4" className={style.DebugTimer}>
      <tbody>
        <tr>
          <td align="center" style={{ backgroundColor: '#041E42', borderRadius: 5, color: '#ffffff', fontWeight: 500, lineHeight: '35px', width: '50%' }}>
            {state.expiringIn.substring(3, 5)}
          </td>
          <td align="center" style={{ color: '#041E42', padding: 4 }}>
            :
          </td>
          <td align="center" style={{ backgroundColor: '#041E42', borderRadius: 5, color: '#ffffff', fontWeight: 500, lineHeight: '35px', width: '50%' }}>
            {state.expiringIn.substring(6, 8)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SessionDebugTimer;